:root {
    --mint: #9ED2BB;
    --sage: #86A789;
    --dark-sage: #618264;
    --off-white: #F9FAFB;
    --light-gray: #E5E7EB;
    --gray: #6B7280;
    --dark-gray: #374151;
    --black: #1F2937;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  body {
    background-color: var(--off-white);
    color: var(--dark-gray);
    line-height: 1.6;
  }
  
  a {
    color: var(--dark-sage);
    text-decoration: none;
    transition: color 0.2s;
  }
  
  a:hover {
    color: var(--sage);
  }
  
  .container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 2rem;
  }
  
  header {
    padding: 2rem 0;
    border-bottom: 1px solid var(--light-gray);
  }
  
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--black);
  }
  
  .nav-links {
    display: flex;
    gap: 1.5rem;
  }
  
  .nav-links a {
    font-weight: 500;
  }
  
  .hero {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .profile-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid var(--mint);
  }
  
  .hero-content h1 {
    color: var(--black);
    margin-bottom: 0.5rem;
    font-size: 2.5rem;
  }
  
  .hero-content h2 {
    color: var(--gray);
    font-weight: 500;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  
  .hero-content p {
    margin-bottom: 1.5rem;
  }
  
  .social-links {
    display: flex;
    gap: 1rem;
  }
  
  .social-links a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  section {
    padding: 4rem 0;
    border-bottom: 1px solid var(--light-gray);
  }
  
  section h2 {
    color: var(--black);
    margin-bottom: 2rem;
    position: relative;
    display: inline-block;
  }
  
  section h2::after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 50%;
    height: 3px;
    background-color: var(--mint);
  }
  
  .experience-item {
    margin-bottom: 3rem;
  }
  
  .experience-item:last-child {
    margin-bottom: 0;
  }
  
  .experience-header {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .company-logo {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
  
  .experience-title h3 {
    color: var(--black);
    margin-bottom: 0.25rem;
  }
  
  .experience-title h4 {
    color: var(--dark-sage);
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
  
  .experience-title p {
    color: var(--gray);
    font-size: 0.9rem;
  }
  
  .experience-details ul {
    list-style-position: inside;
    margin-left: 1rem;
  }
  
  .experience-details li {
    margin-bottom: 0.5rem;
  }
  
  .experience-details li:last-child {
    margin-bottom: 0;
  }
  
  .education-item {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .school-logo {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
  
  .education-details h3 {
    color: var(--black);
    margin-bottom: 0.25rem;
  }
  
  .education-details p {
    margin-bottom: 0.25rem;
  }
  
  .projects-section {
    background-color: white;
  }
  
  .project-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .project-card {
    border: 1px solid var(--light-gray);
    border-radius: 0.5rem;
    overflow: hidden;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  }
  
  .project-card img {
    width: 100%;
    height: 160px;
    object-fit: cover;
  }
  
  .project-card-content {
    padding: 1.5rem;
  }
  
  .project-card-content h3 {
    margin-bottom: 0.5rem;
    color: var(--black);
  }
  
  .project-card-content p {
    margin-bottom: 1rem;
    color: var(--gray);
  }
  
  .blog-preview {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .blog-card {
    border: 1px solid var(--light-gray);
    border-radius: 0.5rem;
    overflow: hidden;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  }
  
  .blog-card-content {
    padding: 1.5rem;
  }
  
  .blog-card-content h3 {
    margin-bottom: 0.5rem;
    color: var(--black);
  }
  
  .blog-card-content p.date {
    color: var(--gray);
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  
  .blog-card-content p {
    color: var(--dark-gray);
  }
  
  .cta-button {
    display: inline-block;
    background-color: var(--dark-sage);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  .cta-button:hover {
    background-color: var(--sage);
    color: white;
  }
  
  footer {
    padding: 2rem 0;
    text-align: center;
    color: var(--gray);
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 0 1rem;
    }
    
    .hero {
      flex-direction: column;
      text-align: center;
    }
  
    .social-links {
      justify-content: center;
    }
  
    .experience-header {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    
    .role-container {
      margin-left: 0 !important;
      text-align: center;
    }
  
    .education-item {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    
    .project-cards, 
    .blog-preview {
      grid-template-columns: 1fr;
    }
    
    .hero-content h1 {
      font-size: 2rem;
    }
    
    .hero-content h2 {
      font-size: 1.2rem;
    }
  }
  